import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import SEO from "../components/seo"
import ShopLayout from "../templates/shop-layout"
import { getPrice } from "../utils/getPrice"

import { ShopContext } from "../context/shop-context"

const ShopPage = () => {
  const { checkout } = useContext(ShopContext)
  const { allShopifyProduct } = useStaticQuery(graphql`
    query {
      allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
        edges {
          node {
            id
            title
            handle
            createdAt
            priceRange {
              minVariantPrice {
                amount
              }
            }
            images {
              id
              originalSrc
              localFile {
                childImageSharp {
                  fluid(maxWidth: 910) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            variants {
              title
              availableForSale
            }
            availableForSale
          }
        }
      }
    }
  `)

  const products = allShopifyProduct.edges
  return (
    <ShopLayout>
      <SEO title="Yams Studio: Shop" />
      <div className="grid grid-cols-2 gap-4 mx-5 my-12">
        {products ? (
          products.map(({ node }) => (
            <div key={node.id}>
              <Link to={`/product/${node.handle}/`}>
                <img
                  src={node.images[0].originalSrc}
                  alt={node.title}
                  className="w-50"
                  key={node.images[0].id}
                />
                <h3 className="text-md md:text-xl leading-none mt-2">
                  {node.title}
                </h3>
                <p className="text-sm md:text-base mt-1">
                  {getPrice(node.priceRange.minVariantPrice.amount, checkout)}{" "}
                  {checkout?.currencyCode}
                </p>
              </Link>
            </div>
          ))
        ) : (
          <p>No products found! Check back soon.</p>
        )}
      </div>
    </ShopLayout>
  )
}

export default ShopPage
